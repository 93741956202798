import React, {useState} from "react";
import './Footer.css';
import {useTheme} from "@mui/material/styles";
import {
    Box,
    Button,
    Typography
} from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import Toolbar from "@mui/material/Toolbar";
import {useNavigate} from "react-router-dom";
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';

export default function FooterComponent() {
    const nav = useNavigate();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const [showSupport, setShowSupport] = useState(false);

    const closeSupport = () => {
        setShowSupport(false);
    }

    const openSupport = () => {
        setShowSupport(true);
    }

    return (
        <footer className="footer">
            <Box sx={{display: isMobile ? 'none' : 'block', backgroundColor: 'white'}}>
                <Toolbar sx={{flexGrow: 1}}>
                    <Typography variant={'body2'}>
                        Проект «Мерч-метрика» разработан СберМаркетинг и MAER
                        <br/><small><i>при поддержке исследовательского центра АКАР</i></small>
                    </Typography>
                    <Box sx={{flexGrow: 1, ml: 1}}>

                    </Box>
                    <Button
                        onClick={() => {
                            nav('/terms-of-use');
                        }}
                        size={'small'}
                    >Пользовательское соглашение</Button>
                    <Button
                        onClick={() => {
                            nav('/publication');
                        }}
                        size={'small'}
                        sx={{ml: 1}}
                    >Условия публикации</Button>
                    <Button
                        onClick={() => {
                            openSupport();
                        }}
                        size={'small'}
                        sx={{ml: 1}}
                    >Поддержка</Button>
                </Toolbar>
            </Box>
            <Box sx={{display: isMobile ? 'block' : 'none', textAlign: 'center', backgroundColor: 'white'}}>
                <Typography variant={'body2'}>
                    <small>Проект «Мерч-метрика» разработан СберМаркетинг и MAER<br/>
                        <i>при поддержке исследовательского центра АКАР</i></small>
                </Typography>
                <Button
                    onClick={() => {
                        nav('/terms-of-use');
                    }}
                    size={'small'}
                >Пользовательское соглашение</Button>
                <Button
                    onClick={() => {
                        nav('/publication');
                    }}
                    size={'small'}
                >Условия публикации</Button>
                <Button
                    onClick={() => {
                        openSupport();
                    }}
                    size={'small'}
                >Поддержка</Button>
            </Box>
            <Dialog
                open={showSupport}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Поддержка"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Поддержка МерчМетрики: <a href="mailto:help@merchmetrika.ru">help@merchmetrika.ru</a>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={closeSupport}>Закрыть</Button>
                </DialogActions>
            </Dialog>
        </footer>
    );
};
