import {
    Grid,
    Paper,
    Box,
    Button
} from "@mui/material";
import React from "react";
import {useEffect, useState} from "react";
import {Requests, Calculator} from "../../utils/network";
import MerchEdit from '../MerchEdit/MerchEditComponent';
import CityEdit from '../CityEdit/CityEditComponent';
import './Editor.css';

export default function EditorComponent() {
    const [merch, setMerch] = useState([]);
    const [merchGroups, setMerchGroups] = useState([]);
    const [merchByGroups, setMerchByGroups] = useState([]);
    const [cityTypes, setCityTypes] = useState([]);

    const [user, setUser] = useState(Requests.user);

    const [openMerchEditId, setOpenMerchEditId] = useState(false);
    const [openCityEditId, setOpenCityEditId] = useState(false);
    const [openMerchCreate, setOpenMerchCreate] = useState(false);

    const updateMerch = () => {
        Calculator.stuff().then((result) => {
            console.log(result);
            setUser(Requests.user);

            setMerch(result.merch);
            setMerchGroups(result.merchGroups);

            setMerchByGroups([
                result.merch.filter((item) => item.merch_group_id === 1),
                result.merch.filter((item) => item.merch_group_id === 2),
                result.merch.filter((item) => item.merch_group_id === 3)
            ])

            setCityTypes(result.cityTypes);
        });
    }

    useEffect(() => {
        updateMerch();
    }, [])

    const truncate = (str, n) => {
        return (str.length > n) ? str.slice(0, n - 1) + '...' : str;
    }

    const RenderMerchEdit = ({merchData}) => {
        return (
            <MerchEdit
                key={merchData.id}
                merch={merchData}
                open={!openMerchCreate && merchData.id === openMerchEditId}
                onClose={() => {
                    setOpenMerchEditId(false);
                }}
                onSave={async () => {
                    setOpenMerchEditId(false);
                    updateMerch();
                }}
                merchGroups={merchGroups}
            />
        );
    };

    const RenderMerchCreate = () => {
        return (
            <MerchEdit
                key={0}
                open={openMerchCreate}
                onClose={() => {
                    setOpenMerchCreate(false);
                }}
                onSave={async () => {
                    setOpenMerchCreate(false);
                    updateMerch();
                }}
                merchGroups={merchGroups}
            />
        );
    }

    const RenderCityEdit = ({cityData}) => {
        return (
            <CityEdit
                key={cityData.id}
                cityType={cityData}
                open={!openMerchCreate && !openMerchEditId && cityData.id === openCityEditId}
                onClose={() => {
                    setOpenCityEditId(false);
                }}
                onSave={async () => {
                    setOpenCityEditId(false);
                    updateMerch();
                }}
            />
        );
    };

    return (
        <Grid container spacing={5} alignItems="flex-start">
            <Grid item xs={12} sm={12}>
                <Paper
                    elevation={0}
                    sx={{
                        minHeight: '50vh',
                    }}
                >
                    <small><b>Мерч:</b></small>

                    <Box>
                        {merchByGroups.map((items, key) => (
                            <Box sx={{mb: '1em'}} key={key}>
                                {items.map((item) => (
                                    <span key={'merch' + item.id}>
                                        <Button
                                            className='merchButton'
                                            variant='outlined'
                                            size='small'
                                            onClick={() => {
                                                setOpenMerchEditId(item.id);
                                            }}
                                            sx={{margin: '2px'}}
                                        >{truncate(item.name, 80)}</Button>
                                        <RenderMerchEdit merchData={item}/>
                                    </span>
                                ))}
                            </Box>
                        ))}
                    </Box>

                    <Box sx={{mt: 3, display: 'flex', justifyContent: 'space-between', width: '100%'}}>
                        <Button
                            variant="contained"
                            className="calcButton"
                            onClick={() => {
                                setOpenMerchCreate(true);
                            }}
                            size="medium">
                            Добавить мерч
                        </Button>
                        <RenderMerchCreate/>
                    </Box>

                    <br/>
                    <small><b>Города:</b></small>

                    <Box>
                        {cityTypes.map((item) => (
                            <span key={'city' + item.id}>
                                <Button
                                    className='merchButton'
                                    variant='outlined'
                                    size='small'
                                    onClick={() => {
                                        setOpenCityEditId(item.id);
                                    }}
                                    sx={{margin: '2px'}}
                                >{item.name}</Button>
                                <RenderCityEdit cityData={item}/>
                            </span>
                        ))}
                    </Box>
                </Paper>
            </Grid>
        </Grid>
    )
        ;
}
