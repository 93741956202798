import {Container, CssBaseline} from "@mui/material";
import {Box} from "@mui/material";
import React from "react";
import Toolbar from "@mui/material/Toolbar";
import LogoImg from "../../logo.png";
import './PublicationConditions.css';
import {isAuthenticated} from "../../App";

export default function TermsOfUse() {
    return (
        <Box>
            <CssBaseline/>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center'
                }}
                aria-label="mailbox folders"
                className='publication-conditions'
            >
                <Toolbar/>
                <Box sx={{mb: 4, display: isAuthenticated ? 'none' : 'block'}}>
                    <a href="/"><img src={LogoImg} alt="" className="logo"/></a>
                </Box>
                <Container maxWidth="md">
                    <h3>Условия публикации</h3>
                    <p>Публикация в интернете:</p>
                    <p>Вы можете совершенно свободно и бесплатно публиковать результаты расчетов Калькулятора и информацию о Калькуляторе на любом информационном ресурсе при соблюдении следующих условий:</p>
                    <p>- На каждой странице, на которой присутствует информация о Калькуляторе и/или результаты, полученные при помощи Калькулятора, в конце материала или в анонсе должна стоять прямая ссылка на сайт Калькулятора — https://merchmetrika.ru/ Например, «Согласно данным, полученным при помощи калькулятора «Мерч-метрика»…). Ссылки через редирект не допускаются.</p>
                    <p>- Мы оставляем за собой право запретить публикацию информации о Калькуляторе и/или результатов, полученных при помощи Калькулятора, на любом информационном ресурсе без объяснения причин. Если вы получите от, как правообладателей, уведомление об этом, то должны в срок три календарных дня исключить упоминание о Калькуляторе с вашего информационного ресурса с момента получения уведомления.</p>
                </Container>
            </Box>
        </Box>
    )
}