import {
    TextField,
    Grid,
    Paper,
    Box,
    Button
} from "@mui/material";
import React from "react";
import {useEffect, useState} from "react";
import {Requests, Calculator} from "../../utils/network";

import './Calculator.css';

export default function CalculatorComponent() {
    const [merch, setMerch] = useState([]);
    const [merchGroups, setMerchGroups] = useState([]);
    const [merchByGroups, setMerchByGroups] = useState([]);
    const [cityTypes, setCityTypes] = useState([]);

    const [selectedMerch, setSelectedMerch] = useState(0);
    const [selectedCityType, setSelectedCityType] = useState(1);
    const [count, setCount] = useState();
    const [price, setPrice] = useState();

    const [ots, setOts] = useState('-');
    const [otsPerItem, setOtsPerItem] = useState('-');

    const [coverage, setCoverage] = useState('-');
    const [coveragePerItem, setCoveragePerItem] = useState('-');

    const [frequency, setFrequency] = useState('-');
    const [totalFrequency, setTotalFrequency] = useState('-');
    const [cpt, setCpt] = useState('-');

    const [merchDays, setMerchDays] = useState([]);

    const [totalPrice, setTotalPrice] = useState('-');

    const [user, setUser] = useState(Requests.user);

    useEffect(() => {
        Calculator.stuff().then((result) => {
            console.log('Calculator stuff:', result);
            setUser(Requests.user);

            setMerch(result.merch);
            setMerchGroups(result.merchGroups);

            setMerchByGroups([
                result.merch.filter((item) => item.merch_group_id === 1),
                result.merch.filter((item) => item.merch_group_id === 2),
                result.merch.filter((item) => item.merch_group_id === 3)
            ])

            setCityTypes(result.cityTypes);
        });
    }, [])

    const truncate = (str, n) => {
        return (str.length > n) ? str.slice(0, n-1) + '...' : str;
    }
    const calculate = () => {
        Calculator.calculate(selectedMerch, selectedCityType, price, count).then((result) => {
            console.log('Calculator results:', result);
            setOts(Math.round(parseFloat(result.ots)).toLocaleString().replaceAll(',', ' '));
            setCoverage(Math.round(parseFloat(result.coverage)).toLocaleString().replaceAll(',', ' '));
            setFrequency(parseFloat(result.frequency).toFixed(2));
            setCpt(parseFloat(result.cpt).toFixed(2));
            setTotalPrice(parseInt(result.totalPrice).toLocaleString().replaceAll(',', ' '));
            setMerchDays(parseFloat(result.days).toFixed(0));
        });
    }

    return (
        <Grid container spacing={5} alignItems="flex-start">
            <Grid item xs={12} sm={8}>
                <Paper
                    elevation={0}
                    sx={{
                        minHeight: '50vh',
                    }}
                >
                    <small><b>Выберите тип мерча:</b></small>

                    <Box>
                        {merchByGroups.map((items, key) => (
                            <Box sx={{mb: '1em'}} key={key}>
                                {items.map((item) => (
                                    <Button
                                        className={item.id === selectedMerch ? 'merchButtonSelected' : 'merchButton'}
                                        variant='outlined'
                                        size='small'
                                        onClick={() => {
                                            console.log(item.id);
                                            setSelectedMerch(item.id)
                                        }}
                                        sx={{margin: '2px'}}
                                        key={'merch' + item.id}
                                    >{truncate(item.name, 80)}</Button>
                                ))}
                            </Box>
                        ))}
                    </Box>

                    <br/>
                    <small><b>Размер города:</b></small>

                    <Box>
                        {cityTypes.map((item) => (
                            <Button
                                className={item.id === selectedCityType ? 'merchButtonSelected' : 'merchButton'}
                                variant='outlined'
                                size='small'
                                onClick={() => {
                                    console.log(item.id);
                                    setSelectedCityType(item.id);
                                }}
                                sx={{margin: '2px'}}
                                key={'cityType' + item.id}
                            >{item.name}</Button>
                        ))}
                    </Box>

                    <br/>

                    <Box>
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    required
                                    id="input-count"
                                    label='Количество:'
                                    variant="standard"
                                    type="number"
                                    size='small'
                                    defaultValue={count}
                                    onChange={(e) => {
                                        if (e.target.value > 1000000) {
                                            e.target.value = 1000000;
                                        }
                                        if (e.target.value < 0) {
                                            e.target.value = 0;
                                        }
                                        setCount(e.target.value);
                                    }}
                                    focused
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    required
                                    id="input-price"
                                    label='Стоимость за единицу:'
                                    variant="standard"
                                    type="number"
                                    size='small'
                                    defaultValue={price}
                                    onChange={(e) => {
                                        if (e.target.value > 1000000) {
                                            e.target.value = 1000000;
                                        }
                                        if (e.target.value < 0) {
                                            e.target.value = 0;
                                        }
                                        setPrice(e.target.value);
                                    }}
                                    focused
                                />
                            </Grid>
                        </Grid>
                    </Box>

                    <br/>

                    <Box>
                        <Button
                            onClick={() => {
                                calculate();
                            }}
                            className='calcButton'
                            disabled={!selectedMerch || !selectedCityType || !count || !price}
                        >Рассчитать</Button>
                    </Box>
                </Paper>
            </Grid>
            <Grid item xs={12} sm={4}>
                <Paper
                    elevation={3}
                    sx={{
                        minHeight: '30vh',
                        borderRadius: '10px',
                        background: '#ECF0F4',
                        boxShadow: '0px 4px 25px 0px rgba(0, 0, 0, 0.20)',
                        padding: '2em',
                    }}
                >
                    <div className='resultsTitle'>OTS</div>
                    <div className='resultsSubTitle'>Количество контактов с мерчём за весь срок службы *</div>

                    <span className='resultsValue'>{ots}</span>

                    <div className='resultsTitle'>Охват</div>
                    <div className='resultsSubTitle'>Число людей, которые видели мерч, хотя бы 1 раз *</div>

                    <span className='resultsValue'>{coverage}</span>

                    <div className='resultsTitle'>Частота в месяц</div>
                    <div className='resultsSubTitle'>Среднее количество контактов единицы мерча с одним и тем же человеком *</div>
                    <span className='resultsValue'>≈ {frequency}</span>

                    <div className='resultsTitle'>CPT</div>
                    <div className='resultsSubTitle'>Cтоимость 1000 показов на этом мерче *</div>
                    <span className='resultsValue'>{cpt} ₽</span>

                    <div className='resultsTitle'>Стоимость мерча</div>
                    <div className='resultsSubTitle'>По прайсу</div>
                    <span className='resultsValue'>{totalPrice} ₽</span>

                    { merchDays > 0 ?
                        <div className='resultsSubTitle'><sup>*</sup> Средний срок службы {merchDays} дней</div> :
                        <span></span> }
                </Paper>
            </Grid>
        </Grid>
    );
}
