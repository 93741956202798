import {Container, CssBaseline, Paper} from "@mui/material";
import {Box} from "@mui/material";
import React from "react";
import Toolbar from "@mui/material/Toolbar";
import LogoImg from "../../logo.png";

export function CheckEmail() {
    return (
        <Box>
            <CssBaseline/>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center'
                }}
                aria-label="mailbox folders"
            >
                <Toolbar/>
                <Container maxWidth="md">
                    <Box sx={{
                        marginTop: 3,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center'
                    }}>
                        <Box sx={{mb: 4}}>
                            <a href="/"><img src={LogoImg} alt="" className="logo"/></a>
                        </Box>
                        <Paper
                            variant='outlined'
                            sx={{
                                minHeight: '30vh',
                                borderRadius: '10px',
                                border: 'solid #000000 1px',
                                background: '#ECF0F4',
                                padding: '2em',
                            }}
                        >
                            Пожалуйста, проверьте почту.<br/>
                            На указанный адрес отправлена ссылка для завершения регистрации
                        </Paper>
                    </Box>
                </Container>
            </Box>
        </Box>
    )
}