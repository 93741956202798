import Toolbar from "@mui/material/Toolbar";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import React from "react";
import LogoImg from '../../logo.png'
import LogoPdf from './pdf.png'
import Button from "@mui/material/Button";

import {Grid} from "@mui/material";
import Link from '@mui/material/Link';
import useMediaQuery from '@mui/material/useMediaQuery';
import {useTheme} from '@mui/material/styles';

import {Requests} from "../../utils/network";
import { useLocation } from 'react-router-dom';

import './AppBar.css';
import {isAdmin, isManager} from "../../App";

export default function AppBarComponent() {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    const isEditor = useLocation().pathname.startsWith('/editor');
    const isStats = useLocation().pathname.startsWith('/stats');

    return (
        <>
            <AppBar
                position="static"
                color="transparent"
                elevation={0}
                sx={{marginBottom: '2em', display: isMobile ? 'none' : 'block'}}
            >
                <Toolbar sx={{flexGrow: 1}}>
                    <Box>
                        <a href="/"><img src={LogoImg} alt="" className="logo"/></a>&nbsp;
                    </Box>
                    <Box sx={{flexGrow: 1, ml: 1}}>
                        <nav>
                            <Link
                                color="text.primary"
                                href="/"
                                sx={{my: 1, mx: 1.5, textUnderlineOffset: '0.5em', textDecoration: (isEditor || isStats) ? 'none' : 'underline'}}
                            >
                                Калькулятор
                            </Link>
                            {isAdmin && <Link
                                color="text.primary"
                                href="/editor"
                                sx={{my: 1, mx: 1.5, textUnderlineOffset: '0.5em', textDecoration: isEditor ? 'underline' : 'none'}}
                            >
                                Редактор
                            </Link>}
                            {(isAdmin || isManager) && <Link
                                color="text.primary"
                                href="/stats"
                                sx={{my: 1, mx: 1.5, textUnderlineOffset: '0.5em', textDecoration: isStats ? 'underline' : 'none'}}
                            >
                                Статистика
                            </Link>}
                            <Link
                                color="text.primary"
                                href='/SM_MAER_Мерчметрика.pdf'
                                target="_blank"
                                sx={{my: 1, mx: 1.5, textDecoration: 'none'}}
                            >
                                Методология
                                <img src={LogoPdf} alt="" className="pdfHint"/>
                            </Link>
                        </nav>
                    </Box>
                    <Button
                        onClick={() => {
                            Requests.logout();
                            window.location.reload();
                        }}
                        className='exitButton'
                    >Выход</Button>
                </Toolbar>
            </AppBar>
            <AppBar
                position="static"
                color="transparent"
                elevation={0}
                sx={{marginTop: '1em', marginBottom: '2em', display: isMobile ? 'block' : 'none'}}
            >
                <Grid container spacing={2} alignItems="center" justifyContent="center">
                    <Grid item md={12}>
                        <img src={LogoImg} alt="" className="logo"/>
                    </Grid>
                    <Grid item md={12}>
                        <nav>
                            <Link
                                color="text.primary"
                                target="_blank"
                                href='/SM_MAER_Мерчметрика.pdf'
                                sx={{my: 1, mx: 1.5, textDecoration: 'none'}}
                            >
                                Презентация
                                <img src={LogoPdf} alt="" className="pdfHint"/>
                            </Link>
                            <Link
                                color="text.primary"
                                onClick={() => {
                                    Requests.logout();
                                    window.location.reload();
                                }}
                                sx={{my: 1, mx: 1.5, textDecoration: 'none'}}
                            >
                                Выход
                            </Link>
                        </nav>
                    </Grid>
                </Grid>
            </AppBar>
        </>
    );
}
