import {useState} from "react";
import Toolbar from "@mui/material/Toolbar";
import React from "react";
import {useNavigate} from "react-router-dom";
import {
    Box,
    Button,
    Container,
    TextField,
    CssBaseline,
    Paper,
    FormControl,
    FormControlLabel
} from '@mui/material'
import { SmartCaptcha } from '@yandex/smart-captcha';
import {User} from '../../utils/network';
import LogoImg from "../../logo.png";
import Link from "@mui/material/Link";
import Checkbox from '@mui/material/Checkbox';


export function RegistrationPage() {
    const nav = useNavigate();
    const [token, setToken] = useState('');
    const [errors, setErrors] = useState([]);
    const [termsAgree, setTermsAgree] = useState(false);
    const [sumbittingInProcess, setSumbittingInProcess] = useState(false);

    const setTermsAgreeChecked = function (e) {
        setTermsAgree(e.currentTarget.checked);
    }

    const handleSubmit = (ev) => {
        ev.preventDefault();
        setSumbittingInProcess(true);
        const formData = new FormData(ev.currentTarget);

        let registrationForm = {
            last_name: formData.get('last_name'),
            first_name: formData.get('first_name'),
            middle_name: formData.get('middle_name'),
            company: formData.get('company'),
            post: formData.get('post'),
            email: formData.get('email'),
        };

        User.register(registrationForm).then((response) => {
            if (response.errors) {
                setErrors(response.errors);
                setSumbittingInProcess(false);
            } else {
                nav('/check-email');
            }
        });
    };

    return (
        <Box>
            <CssBaseline/>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center'
                }}
                aria-label="mailbox folders"
            >
                <Toolbar/>
                <Container maxWidth="md">
                    <Box sx={{
                        marginTop: 3,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center'
                    }}>
                        <Box sx={{mb: 4}}>
                            <a href="/"><img src={LogoImg} alt="" className="logo"/></a>
                        </Box>
                        <Paper
                            variant='outlined'

                            sx={{
                                minHeight: '30vh',
                                borderRadius: '10px',
                                border: 'solid #000000 1px',
                                background: '#ECF0F4',
                                padding: '2em',
                            }}
                        >
                            <Box component={"form"} onSubmit={handleSubmit}>
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    name="last_name"
                                    label="Фамилия"
                                    type="text"
                                    id="last_name"
                                    autoComplete="last_name"
                                    autoFocus
                                    error={!!errors.last_name}
                                    helperText={errors.last_name || ''}
                                />
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    name="first_name"
                                    label="Имя"
                                    type="text"
                                    id="first_name"
                                    autoComplete="first_name"
                                    error={!!errors.first_name}
                                    helperText={errors.first_name || ''}
                                />
                                <TextField
                                    margin="normal"
                                    fullWidth
                                    name="middle_name"
                                    label="Отчество"
                                    type="text"
                                    id="middle_name"
                                    autoComplete="middle_name"
                                    error={!!errors.middle_name}
                                    helperText={errors.middle_name || ''}
                                />
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    name="company"
                                    label="Организация"
                                    type="text"
                                    id="company"
                                    autoComplete="company"
                                    error={!!errors.company}
                                    helperText={errors.company || ''}
                                />
                                <TextField
                                    margin="normal"
                                    fullWidth
                                    name="post"
                                    label="Должность"
                                    type="text"
                                    id="post"
                                    autoComplete="post"
                                    error={!!errors.post}
                                    helperText={errors.post || ''}
                                />
                                <TextField
                                    margin={"normal"}
                                    required
                                    fullWidth
                                    id="email"
                                    name="email"
                                    label="Email"
                                    autoComplete="email"
                                    error={!!errors.email}
                                    helperText={errors.email || 'Будет использоваться как логин для входа'}
                                    sx={{mb: 2}}
                                />
                                <Box sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center'
                                }}>
                                    <FormControl>
                                        <FormControlLabel control={<Checkbox onChange={setTermsAgreeChecked}/>} label={<div>Подтвердите, что вы принимаете <a target="_blank" href="/terms-of-use">пользовательское соглашение</a> и <a target="_blank" href="/publication">условия публикации</a></div>} />
                                    </FormControl>
                                    <SmartCaptcha
                                        language="ru"
                                        sitekey="ysc1_OjaJ7n6NTn15HsYbca7asjxDmhWhl9C3v1hNAyJi1ab9f824"
                                        onSuccess={setToken} />
                                    <Button
                                        className='exitButton'
                                        variant={"outlined"}
                                        type={"submit"}
                                        disabled={!token || !termsAgree || sumbittingInProcess}
                                        sx={{mt: 2}}
                                    >
                                        { sumbittingInProcess ? 'Регистрация...' : 'Регистрация' }
                                    </Button>
                                    <Link
                                        sx={{mt: 2}}
                                        variant={"body2"}
                                        href={"/"}
                                    >
                                        Вход
                                    </Link>
                                </Box>
                            </Box>
                        </Paper>
                    </Box>
                </Container>
            </Box>
        </Box>
    );
}
