import {Container, CssBaseline, Paper} from "@mui/material";
import {Box} from "@mui/material";
import React from "react";
import Toolbar from "@mui/material/Toolbar";
import LogoImg from "../../logo.png";
import './TermsOfUse.css';
import {isAuthenticated} from "../../App";
export default function TermsOfUse() {
    return (
        <Box>
            <CssBaseline/>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center'
                }}
                aria-label="mailbox folders"
                className='terms-of-use'
            >
                <Toolbar/>
                <Box sx={{mb: 4, display: isAuthenticated ? 'none' : 'block'}}>
                    <a href="/"><img src={LogoImg} alt="" className="logo"/></a>
                </Box>
                <Container maxWidth="md">
                    <h3>Пользовательское соглашение</h3>
                    <p>
                        Политика конфиденциальности персональной информации (далее — Политика)
                        действует в отношении всей информации, которую ООО «Маер Групп» и ООО «С-Маркетинг»
                        и/или их аффилированные лица, включая все лица, входящие
                        в одну группу с ООО «Маер Групп» и ООО «С-Маркетинг» (далее — Компания),
                        могут получить о Пользователе во время использования настоящего сервиса – калькулятор «Мерч-Метрика»
                        (далее — Калькулятор) и в ходе исполнения Компанией любых соглашений и договоров с Пользователем.
                        Согласие Пользователя с Политикой, выраженное им в рамках отношений с одним из перечисленных лиц,
                        распространяется на все остальные перечисленные лица.
                        Использование Калькулятора означает безоговорочное согласие Пользователя с настоящей
                        Политикой и указанными в ней условиями обработки его персональной информации;
                        в случае несогласия с этими условиями Пользователь должен воздержаться от использования Калькулятора.
                    </p>

                    <p>1. Персональная информация Пользователей, которую обрабатывает Компания</p>

                    <p>1.1. В рамках настоящей Политики под «персональной информацией Пользователя» понимаются:</p>

                    <p>1.1.1. Персональная информация, которую Пользователь предоставляет о себе самостоятельно при регистрации (создании учётной записи) или в процессе использования Калькулятора, включая персональные данные Пользователя.</p>
                    <p>1.1.2. Данные, которые автоматически передаются Калькулятору в процессе его использования с помощью установленного на устройстве Пользователя программного обеспечения, в том числе IP-адрес, данные файлов cookie, информация о браузере Пользователя (или иной программе, с помощью которой осуществляется доступ к Калькулятору), технические характеристики оборудования и программного обеспечения, используемых Пользователем, дата и время доступа к Калькулятору, адреса запрашиваемых страниц и иная подобная информация.</p>
                    <p>1.2. Настоящая Политика применима только к информации, обрабатываемой в ходе использования Калькулятора. Компания не контролирует и не несет ответственность за обработку информации сайтами третьих лиц, на которые Пользователь может перейти по ссылкам, доступным на сайтах Компании, в том числе в результатах поиска.</p>
                    <p>1.3. Компания не проверяет достоверность персональной информации, предоставляемой Пользователем, и не имеет возможности оценивать его дееспособность. Однако Компания исходит из того, что пользователь предоставляет достоверную и достаточную персональную информацию и поддерживает эту информацию в актуальном состоянии.</p>
                    <p>2. Цели обработки персональной информации Пользователей</p>
                    <p>2.1. Компания собирает и хранит только ту персональную информацию, которая необходима для предоставления Калькулятора или исполнения соглашений и договоров с Пользователем, за исключением случаев, когда законодательством предусмотрено обязательное хранение персональной информации в течение определенного законом срока.</p>
                    <p>2.2. Персональную информацию Пользователя Компания обрабатывает в следующих целях:</p>
                    <p>2.2.1. Идентификация стороны в рамках Калькулятора, соглашений и договоров с Компанией;</p>
                    <p>2.2.2. Связь с Пользователем, в том числе направление уведомлений, запросов и информации, касающихся использования Калькулятора, исполнения соглашений и договоров, а также обработка запросов и заявок от Пользователя;</p>
                    <p>2.2.3. Улучшение качества Калькулятора, удобства их использования, разработка новых сервисов;</p>
                    <p>2.2.4. Таргетирование рекламных материалов;</p>
                    <p>2.2.5. Проведение статистических и иных исследований, на основе обезличенных данных.</p>
                    <p>3. Условия обработки персональной информации Пользователей и её передачи третьим лицам.</p>
                    <p>3.1. Компания хранит персональную информацию Пользователей в соответствии с внутренними регламентами.</p>
                    <p>3.2. В отношении персональной информации Пользователя сохраняется ее конфиденциальность, кроме случаев добровольного предоставления Пользователем информации о себе для общего доступа неограниченному кругу лиц.</p>
                    <p>3.3. Компания вправе передать персональную информацию Пользователя третьим лицам в следующих случаях:</p>
                    <p>3.3.1. Пользователь выразил согласие на такие действия;</p>
                    <p>3.3.2. Передача необходима для использования Пользователем Калькулятора либо для исполнения определенного соглашения или договора с Пользователем;</p>
                    <p>3.3.3. Передача предусмотрена российским или иным применимым законодательством в рамках установленной законодательством процедуры;</p>
                    <p>3.3.4. Такая передача происходит в рамках продажи или иной передачи бизнеса (полностью или в части), при этом к приобретателю переходят все обязательства по соблюдению условий настоящей Политики применительно к полученной им персональной информации;</p>
                    <p>3.3.5. В целях обеспечения возможности защиты прав и законных интересов Компании или третьих лиц в случаях, когда Пользователь нарушает настоящую Политику.</p>
                    <p>3.3.6. В результате обработки персональной информации Пользователя путем ее обезличивания получены обезличенные статистические данные, которые передаются третьему лицу для проведения исследований, выполнения работ или оказания услуг по поручению Компании.</p>
                    <p>3.4. При обработке персональных данных Пользователей Компания руководствуется Федеральным законом РФ «О персональных данных».</p>
                    <p>4. Обработка персональной информации при помощи файлов Cookie и счетчиков.</p>
                    <p>4.1. Файлы cookie, передаваемые Компанией оборудованию Пользователя и оборудованием Пользователя Компании, могут использоваться Коммпанией для предоставления Пользователю персонализированных Сервисов, для таргетирования рекламы, которая показывается Пользователю, в статистических и исследовательских целях, а также для улучшения Калькулятора.</p>
                    <p>4.2. Пользователь осознает, что оборудование и программное обеспечение, используемые им для посещения сайтов в сети интернет могут обладать функцией запрещения операций с файлами cookie (для любых сайтов или для определенных сайтов), а также удаления ранее полученных файлов cookie.</p>
                    <p>4.3. Компания вправе установить, что предоставление Калькулятора возможно лишь при условии, что прием и получение файлов cookie разрешены Пользователем.</p>
                    <p>4.4. Структура файла cookie, его содержание и технические параметры определяются Компанией и могут изменяться без предварительного уведомления Пользователя.</p>
                    <p>4.5. Счетчики, размещенные Компанией в Калькуляторе, могут использоваться для анализа файлов cookie Пользователя, для сбора и обработки статистической информации об использовании Калькулятора, а также для обеспечения работоспособности Калькулятора в целом или их отдельных функций в частности. Технические параметры работы счетчиков определяются Компанией и могут изменяться без предварительного уведомления Пользователя.</p>
                    <p>5. Меры, применяемые для защиты персональной информации Пользователя.</p>
                    <p>5.1. Компания принимает необходимые и достаточные организационные и технические меры для защиты персональной информации Пользователя от неправомерного или случайного доступа, уничтожения, изменения, блокирования, копирования, распространения, а также от иных неправомерных действий с ней третьих лиц.</p>
                    <p>6. Изменение Политики конфиденциальности. Применимое законодательство.</p>
                    <p>6.1. Компания имеет право вносить изменения в настоящую Политику конфиденциальности. При внесении изменений в актуальной редакции указывается дата последнего обновления. Новая редакция Политики вступает в силу с момента ее размещения, если иное не предусмотрено новой редакцией Политики.</p>

                    <p>6.2. К настоящей Политике и отношениям между Пользователем и Компанией, возникающим в связи с применением Политики конфиденциальности, подлежит применению право Российской Федерации.</p>
                    <p>7. Обратная связь. Вопросы и предложения</p>
                    <p>7.1. Все предложения или вопросы по поводу настоящей Политики Пользователь вправе направлять в Службу поддержки пользователей help@merchmetrika.ru.</p>
                </Container>
            </Box>
        </Box>
    )
}