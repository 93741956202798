let envUrl = "http://localhost:8501";
if (process.env.NODE_ENV === "production") {
    envUrl = "";
    if (window.location.host.indexOf('staging') >= 0) {
        envUrl = "https://staging.merch.maergroup.ru";
    }
}

const BASE_URL = envUrl;

function populateProps(props) {
    props["headers"] = props["headers"] || {};
    props["headers"]["X-Requested-With"] = "XMLHttpRequest";
    props["headers"]["Content-Type"] = "application/json";
    props["headers"]["Accept"] = "application/json";
    props["credentials"] = "include";
    props["mode"] = "cors";

    let token = getCsrfToken();
    if (token) {
        props["headers"]["X-XSRF-TOKEN"] = token;
    }

    return props;
}

function updateCsrfStorage() {
    let csrf = RegExp('XSRF-TOKEN[^;]+').exec(document.cookie);
    csrf = decodeURIComponent(csrf ? csrf.toString().replace(/^[^=]+./, '') : '');

    if (csrf) {
        window.localStorage.setItem('csrftoken', csrf);
    }
}

function getCsrfToken() {
    updateCsrfStorage();
    return window.localStorage.getItem('csrftoken');
}

function getCsrfHeader() {
    updateCsrfStorage();
    return {
        "X-XSRF-TOKEN": window.localStorage.getItem('csrftoken'),
        "Accept": "application/json",
    };
}

const borderStatuses = [401, 405, 419];

class RequestsClass {
    get(url, props) {
        props = props || {};
        props["method"] = "GET";
        props = populateProps(props);

        return fetch(url, props)
            .then((response) => {
                if (borderStatuses.indexOf(response.status) >= 0) {
                    this.csrfReset();
                }

                return response;
            });
    }

    post(url, props, data) {
        props = props || {};
        props["method"] = "POST";
        props = populateProps(props);

        if (data) {
            if (typeof data == "string") {
                props["body"] = data;
            } else {
                props["body"] = JSON.stringify(data);
            }
        }

        return fetch(url, props)
            .then((response) => {
                if (borderStatuses.indexOf(response.status) >= 0) {
                    this.csrfReset();
                }

                return response;
            });
    }

    set user(data) {
        if (data) {
            window.localStorage.setItem('userdata', JSON.stringify(data));
        } else {
            window.localStorage.removeItem('userdata');
        }
    }

    get user() {
        let user = window.localStorage.getItem('userdata');
        let userObj = null;
        try {
            userObj = JSON.parse(user);
        } catch (e) {
            console.log("Request::user<get> parse failed:", e);
        }

        return userObj;
    }

    requestAuthenticated() {
        let props = populateProps({});

        let now = (new Date()).getTime();
        let mark = window.localStorage.getItem("authLastRequest");
        let user = this.user;

        console.log("Req mark", now - mark);

        if (user && user.id && mark > 0 && now - mark < 2592000000) {
            return new Promise((resolve) => {
                resolve({status: 200});
            });
        }

        return fetch(BASE_URL + '/api/user', props)
            .then((response) => {
                if (borderStatuses.indexOf(response.status) >= 0) {
                    this.csrfReset();
                    return;
                }

                let time = (new Date()).getTime();
                window.localStorage.setItem("authLastRequest", time);
                response.json().then((user) => {
                    this.user = user;
                });

                return response;
            });
    }

    get auth() {
        let user = this.user;
        return !(!user || !user["id"]);
    }

    logout() {
        let props = populateProps({});

        this.user = null;

        window.localStorage.clear();
        window.sessionStorage.clear();

        fetch(BASE_URL + '/api/logout', props);
    }

    csrf() {
        let token = window.localStorage.getItem('csrftoken');
        if (token) {
            return token;
        } else {
            this.get(BASE_URL + '/sanctum/csrf-cookie')
                .then(() => {
                    updateCsrfStorage();
                });
        }
    }

    csrfReset() {
        this.clearData();
        this.csrf();
    }

    clearData() {
        window.localStorage.removeItem('csrftoken');
        window.localStorage.removeItem('userdata');
    }
}

class User {
    static register(data) {
        let props = populateProps({});

        props['method'] = 'POST';
        props["body"] = JSON.stringify(data);

        return fetch(BASE_URL + '/api/register', props)
            .then((response) => {
                if (borderStatuses.indexOf(response.status) >= 0) {
                    Requests.csrfReset();
                }

                return response.json();
            });
    }

    static setPassword(data) {
        let props = populateProps({});

        props['method'] = 'POST';
        props["body"] = JSON.stringify(data);

        return fetch(BASE_URL + '/api/set-password', props)
            .then((response) => {
                if (borderStatuses.indexOf(response.status) >= 0) {
                    Requests.csrfReset();
                }

                return response.json();
            });
    }

    static checkSetPasswordCredentials(data) {
        let props = populateProps({});

        props['method'] = 'POST';
        props["body"] = JSON.stringify(data);

        return fetch(BASE_URL + '/api/check-set-password-credentials', props)
            .then((response) => {
                if (borderStatuses.indexOf(response.status) >= 0) {
                    Requests.csrfReset();
                }

                return response.json();
            });
    }

    static resendRegistrationConfirm(data) {
        let props = populateProps({});

        props['method'] = 'POST';
        props["body"] = JSON.stringify(data);

        return fetch(BASE_URL + '/api/resend-registration-confirm', props)
            .then((response) => {
                if (borderStatuses.indexOf(response.status) >= 0) {
                    Requests.csrfReset();
                }

                return response.json();
            });
    }
}

class Merch {
    static create(data) {
        let props = populateProps({});

        props['method'] = 'POST';

        if (data) {
            if (typeof data == "string") {
                props["body"] = data;
            } else {
                props["body"] = JSON.stringify(data);
            }
        } else {
            throw new Error("merch got empty data object");
        }

        return fetch(BASE_URL + '/api/merch', props)
            .then((response) => {
                if (borderStatuses.indexOf(response.status) >= 0) {
                    Requests.csrfReset();
                }

                return response.json();
            });
    }

    static update(data) {
        let props = populateProps({});

        props['method'] = 'PUT';

        if (data) {
            if (typeof data == "string") {
                props["body"] = data;
            } else {
                props["body"] = JSON.stringify(data);
            }
        } else {
            throw new Error("merch got empty data object");
        }

        return fetch(BASE_URL + '/api/merch/' + data['id'], props)
            .then((response) => {
                if (borderStatuses.indexOf(response.status) >= 0) {
                    Requests.csrfReset();
                }

                return response.json();
            });
    }

    static delete(data) {
        let props = populateProps({});

        props['method'] = 'DELETE';

        return fetch(BASE_URL + '/api/merch/' + data['id'], props)
            .then((response) => {
                if (borderStatuses.indexOf(response.status) >= 0) {
                    Requests.csrfReset();
                }

                return response.json();
            });
    }
}

class City {
    static update(data) {
        let props = populateProps({});

        props['method'] = 'PUT';

        if (data) {
            if (typeof data == "string") {
                props["body"] = data;
            } else {
                props["body"] = JSON.stringify(data);
            }
        } else {
            throw new Error("merch got empty data object");
        }

        return fetch(BASE_URL + '/api/city/' + data['id'], props)
            .then((response) => {
                if (borderStatuses.indexOf(response.status) >= 0) {
                    Requests.csrfReset();
                }

                return response.json();
            });
    }
}

class Calculator {
    static stuff() {
        let props = populateProps({});

        return fetch(BASE_URL + '/api/calculator/stuff', props)
            .then((response) => {
                if (borderStatuses.indexOf(response.status) >= 0) {
                    Requests.clearData();
                }

                return response.json();
            });
    }

    static calculate(merchId, cityTypeId, price, count) {
        let props = populateProps({});

        let q = [];
        q.push(['merchId', merchId]);
        q.push(['cityTypeId', cityTypeId]);
        q.push(['price', price]);
        q.push(['count', count]);

        let params = new URLSearchParams(q);

        return fetch(BASE_URL + '/api/calculator?' + params, props)
            .then((response) => {
                if (borderStatuses.indexOf(response.status) >= 0) {
                    Requests.clearData();
                }

                return response.json();
            });
    }
}

class Statistics {
    static index(page, perPage, filters) {
        let props = populateProps({});

        const name = (filters && filters['search']) ?? null;
        const company = (filters && filters['company']) ?? null;
        const merch = (filters && filters['merch']) ?? [];
        const cityTypes = (filters && filters['city']) ?? [];
        const periodStart = (filters && filters['period.start']) ?? null;
        const periodEnd = (filters && filters['period.end']) ?? null;
        const countMin = (filters && filters['count.min']) ?? null;
        const countMax = (filters && filters['count.max']) ?? null;
        const priceMin = (filters && filters['price.min']) ?? null;
        const priceMax = (filters && filters['price.max']) ?? null;

        let q = [];

        if (page > 0) q.push(['page', page + 1]);
        if (perPage > 0) q.push(['perPage', perPage]);

        if (name) q.push(['search', name]);
        if (company) q.push(['company', company]);
        if (merch && merch.length) q.push(['merch', merch.map((item) => item.value).join(',')]);
        if (cityTypes && cityTypes.length) q.push(['city', cityTypes.map((item) => item.value).join(',')]);
        if (periodStart) q.push(['period.start', periodStart]);
        if (periodEnd) q.push(['period.end', periodEnd]);
        if (countMin) q.push(['count.min', countMin]);
        if (countMax) q.push(['count.max', countMax]);
        if (priceMin) q.push(['price.min', priceMin]);
        if (priceMax) q.push(['price.max', priceMax]);

        let params = new URLSearchParams(q);

        return fetch(BASE_URL + '/api/stats?' + params, props)
            .then((response) => {
                if (borderStatuses.indexOf(response.status) >= 0) {
                    Requests.clearData();
                }

                return response.json();
            })
            .then((response) => {
                return new Page(response);
            });
    }

    static export(filters) {
        let props = populateProps({});

        const name = (filters && filters['search']) ?? null;
        const company = (filters && filters['company']) ?? null;
        const merch = (filters && filters['merch']) ?? [];
        const cityTypes = (filters && filters['city']) ?? [];
        const periodStart = (filters && filters['period.start']) ?? null;
        const periodEnd = (filters && filters['period.end']) ?? null;
        const countMin = (filters && filters['count.min']) ?? null;
        const countMax = (filters && filters['count.max']) ?? null;
        const priceMin = (filters && filters['price.min']) ?? null;
        const priceMax = (filters && filters['price.max']) ?? null;

        let q = [];

        if (name) q.push(['search', name]);
        if (company) q.push(['company', company]);
        if (merch && merch.length) q.push(['merch', merch.map((item) => item.value).join(',')]);
        if (cityTypes && cityTypes.length) q.push(['city', cityTypes.map((item) => item.value).join(',')]);
        if (periodStart) q.push(['period.start', periodStart]);
        if (periodEnd) q.push(['period.end', periodEnd]);
        if (countMin) q.push(['count.min', countMin]);
        if (countMax) q.push(['count.max', countMax]);
        if (priceMin) q.push(['price.min', priceMin]);
        if (priceMax) q.push(['price.max', priceMax]);

        let params = new URLSearchParams(q);

        const link = document.createElement('a');
        link.href = BASE_URL + '/api/stats/export?' + params;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    static totals() {
        let props = populateProps({});

        return fetch(BASE_URL + '/api/stats/totals', props)
            .then((response) => {
                if (borderStatuses.indexOf(response.status) >= 0) {
                    Requests.clearData();
                }

                return response.json();
            });
    }
}

const Requests = new RequestsClass();

class Page {
    _list = [];
    _currentPage = 0;
    _lastPage = 0;
    _total = 0;
    _perPage = 0;

    static defaultPerPage = 50;

    constructor(list) {
        this._list = list["data"] || [];
        this._currentPage = list["current_page"] - 1;
        this._lastPage = list["last_page"] + 0;
        this._total = list["total"] + 0;
        this._perPage = list["per_page"] + 0;
    }

    get currentPage() {
        return this._currentPage;
    }

    get lastPage() {
        return this._lastPage;
    }

    get total() {
        return this._total;
    }

    get perPage() {
        return this._perPage;
    }

    get list() {
        return this._list;
    }

    get length() {
        return this._list.length;
    }

    map(cb) {
        return this._list.map(cb);
    }
}

export {
    BASE_URL,
    Requests,
    User,
    Page,
    Merch,
    City,
    Calculator,
    Statistics,
    getCsrfToken,
    getCsrfHeader,
};
