import {
    Grid,
    Paper,
    Typography,
    Box,
    Dialog,
    DialogTitle, Button,
} from "@mui/material";

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import {TableFooter} from "@mui/material";
import Filter from './Filter'

import React, {useState, useEffect} from "react";
import {Page, Statistics} from '../../utils/network';
import {isAdmin} from "../../App";

export default function Stats() {
    let [filters, setFilters] = useState({});
    let [stats, setStats] = useState([]);
    let [page, setPage] = useState(0);
    let [perPage, setPerPage] = useState(Page.defaultPerPage);
    let [userStats, setUsersStats] = useState({'total': 0, 'active': 0, 'calculated': 0});
    let [openRowId, setOpenRowId] = useState(false);
    let [exportBusy, setExportBusy] = useState(false);

    useEffect(() => {
        Statistics.totals().then((totals) => {
            setUsersStats(totals);
        });
    }, []);

    useEffect(() => {
        Statistics.index(page, perPage, filters).then((stats) => {
            setStats(stats);
        });
    }, [page, perPage, filters]);

    const ResultsDialog = function ({open, onClose, row}) {

        const handleClose = (...ev) => {
            onClose(...ev);
        };

        return (
            <Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth={'md'}>
                <DialogTitle>Результаты расчёта</DialogTitle>
                <Box sx={{p: 3, pt: 0, width: '100%', overflowX: 'hidden'}}>
                    <p>
                        Когда был сделан расчёт: <strong>{(new Date(row.created_at)).toLocaleString('ru-RU')}</strong>
                    </p>
                    <p>
                        Кто делал
                        расчёт: <strong>{row.user.name} {row.user.company ? '(' + row.user.company + (row.user.post ? ', ' + row.user.post : '') + ')' : ''}</strong>
                    </p>
                    <p>
                        Мерч: <strong>{row.merch.name}</strong>
                    </p>
                    <p>
                        Тип города: <strong>{row.city_type.name}</strong>
                    </p>

                    <hr/>

                    <p>
                        OTS: <strong>{Math.round(parseFloat(row.results.ots)).toLocaleString().replaceAll(',', ' ')}</strong>
                    </p>
                    <p>
                        Охват: <strong>{Math.round(parseFloat(row.results.coverage)).toLocaleString().replaceAll(',', ' ')}</strong>
                    </p>
                    <p>
                        Частота в месяц: <strong>{parseFloat(row.results.frequency).toFixed(2)}</strong>
                    </p>
                    <p>
                        CPT: <strong>{parseFloat(row.results.cpt).toFixed(2)}</strong>
                    </p>
                    <p>
                        Стоимость
                        мерча: <strong>{parseInt(row.results.totalPrice).toLocaleString().replaceAll(',', ' ')}</strong>
                    </p>
                </Box>
            </Dialog>
        )
    };

    return (
        <>
            <Grid container spacing={5} alignItems="flex-start">
                <Grid item xs={12} sm={12}>
                    {isAdmin &&
                        <Paper
                            elevation={0}
                            sx={{
                                mb: '1em',
                            }}
                        >
                            <Typography variant={"body2"}>
                                Всего зарегистрировано <strong>{userStats.total}</strong> пользователей,
                                активно <strong>{userStats.active}</strong>,
                                делали расчёты <strong>{userStats.calculated}</strong>
                            </Typography>
                        </Paper>
                    }
                    <Filter onFilterChange={(filter) => {
                        setFilters(filter);
                    }}/>
                    <Paper
                        elevation={0}
                        sx={{
                            mb: '1em',
                            textAlign: 'right'
                        }}
                    >
                        <Button
                            variant='outlined'
                            size='small'
                            disabled={exportBusy}
                            onClick={() => {
                                setExportBusy(true);
                                Statistics.export(filters);
                                setExportBusy(false);
                            }}
                            sx={{margin: '2px'}}
                        >Выгрузить в Excel</Button>
                    </Paper>
                    <Paper
                        elevation={0}
                        sx={{
                            minHeight: '50vh',
                        }}
                    >
                        <TableContainer component={Paper}>
                            <Table sx={{
                                minWidth: 650,
                                "& td": {cursor: 'pointer'},
                                th: {fontWeight: 'bold'},
                                "tr td:nth-of-type(1)": {width: '58px'},
                                "tr td:nth-of-type(3)": {width: '80px'},
                                "tr td:nth-of-type(4)": {width: '130px'},
                                "tr td:nth-of-type(5)": {width: '330px'},
                            }} aria-label="">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Дата/время</TableCell>
                                        <TableCell>Пользователь</TableCell>
                                        <TableCell>Организация</TableCell>
                                        <TableCell>Должность</TableCell>
                                        <TableCell>Тип мерча</TableCell>
                                        <TableCell>Тип города</TableCell>
                                        <TableCell>Количество</TableCell>
                                        <TableCell>Стоимость</TableCell>
                                        <TableCell></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {stats.map((row) => (
                                        <>
                                            <TableRow
                                                key={row.id}
                                                sx={{
                                                    '&:last-child td, &:last-child th': {border: 0}
                                                }}
                                            >
                                                <TableCell>{(new Date(row.created_at)).toLocaleString('ru-RU')}</TableCell>
                                                <TableCell>{row.user ? row.user.name : '-'}</TableCell>
                                                <TableCell>{row.user ? row.user.company : '-'}</TableCell>
                                                <TableCell>{row.user ? row.user.post : '-'}</TableCell>
                                                <TableCell>{row.merch.name}</TableCell>
                                                <TableCell>{row.city_type.name}</TableCell>
                                                <TableCell>{row.count}</TableCell>
                                                <TableCell>{row.price}</TableCell>
                                                <TableCell>
                                                    <Button
                                                        variant='outlined'
                                                        size='small'
                                                        onClick={() => {
                                                            setOpenRowId(row.id);
                                                        }}
                                                        sx={{margin: '2px'}}
                                                    >Результаты</Button>
                                                </TableCell>
                                            </TableRow>
                                            <ResultsDialog open={openRowId && openRowId === row.id} row={row}
                                                           onClose={() => {
                                                               setOpenRowId(false);
                                                           }}/>
                                        </>
                                    ))}
                                </TableBody>
                                {stats.length > 0 ?
                                    <TableFooter>
                                        <TableRow>
                                            <TablePagination
                                                count={stats.total}
                                                page={page}
                                                rowsPerPage={perPage}
                                                onPageChange={(value, newPage) => {
                                                    setPage(newPage);
                                                }}
                                                onRowsPerPageChange={(value) => {
                                                    setPerPage(value.target.value);
                                                    setPage(0);
                                                }}
                                                rowsPerPageOptions={[]}
                                            />
                                        </TableRow>
                                    </TableFooter>
                                    : ''}
                            </Table>
                        </TableContainer>
                    </Paper>
                </Grid>
            </Grid>
        </>
    );
}
