import Toolbar from "@mui/material/Toolbar";
import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {
    Box,
    Button,
    Container,
    TextField,
    CssBaseline,
    Paper
} from '@mui/material'

import {User} from '../../utils/network'
import LogoImg from "../../logo.png";


export function SetPassword() {
    const nav = useNavigate();
    const [tokenIsValid, setTokenIsValid] = useState(true);
    const [errors, setErrors] = useState([]);
    const [submitting, setSubmitting] = useState(false);
    const queryParameters = new URLSearchParams(window.location.search);
    const token = queryParameters.get("token");
    const email = queryParameters.get("email");

    console.log(token, email);

    useEffect(() => {
        User.checkSetPasswordCredentials({'token': token, 'email': email})
            .then((response) => {
                if (response.ok) {
                    setTokenIsValid(true);
                } else {
                    setTokenIsValid(false);
                }
            });
    }, [])

    const handleSubmit = (ev) => {
        setSubmitting(true);

        ev.preventDefault();
        const formData = new FormData(ev.currentTarget);

        const credentials = {
            email: email,
            token: token,
            password: formData.get('password'),
            password_confirmation: formData.get('password_confirmation')
        };

        User.setPassword(credentials).then((response) => {
            if (response.errors) {
                setErrors(response.errors);
            } else {
                nav('/');
            }
            setSubmitting(false);
        })

    };

    const handleReSubmit = (ev) => {
        setSubmitting(true);
        ev.preventDefault();

        const credentials = {
            email: email,
        };

        User.resendRegistrationConfirm(credentials)
            .then((response) => {
                setSubmitting(false);
                if (response.ok) {
                    nav('/check-email');
                }
            })
    }

    return (
        <Box>
            <CssBaseline/>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center'
                }}
                aria-label="mailbox folders"
            >
                <Toolbar/>
                <Container maxWidth="md">
                    <Box sx={{
                        marginTop: 3,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center'
                    }}>
                        <Box sx={{mb: 4}}>
                            <a href="/"><img src={LogoImg} alt="" className="logo"/></a>
                        </Box>
                        <Paper
                            variant='outlined'

                            sx={{

                                minWidth: '30vh',
                                borderRadius: '10px',
                                border: 'solid #000000 1px',
                                background: '#ECF0F4',
                                padding: '2em',
                            }}
                        >
                            { tokenIsValid ?
                            <>
                                <Box sx={{textAlign: 'center'}}>
                                    Для завершения регистрации, задайте пароль
                                </Box>
                                <Box component={"form"} onSubmit={handleSubmit}>
                                    <TextField
                                        margin="normal"
                                        required
                                        fullWidth
                                        name="password"
                                        label="Пароль"
                                        type="password"
                                        id="password"
                                        autoComplete="current-password"
                                        error={!!errors.password}
                                        helperText={errors.password || ''}
                                    />
                                    <TextField
                                        margin="normal"
                                        required
                                        fullWidth
                                        name="password_confirmation"
                                        label="Повтор пароля"
                                        type="password"
                                        id="password_confirmation"
                                        autoComplete="current-password"
                                        error={!!errors.password_confiramtion}
                                        helperText={errors.password_confiramtion || ''}
                                    />
                                    <Box sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                    }}>
                                        <Button
                                            className='exitButton'
                                            variant={"outlined"}
                                            type={"submit"}
                                            sx={{mt: 3}}
                                            disabled={submitting}
                                        >
                                            Задать пароль
                                        </Button>
                                    </Box>
                                </Box>
                            </> :
                            <>
                                <Box sx={{textAlign: 'center'}}>
                                    Токен не действителен
                                </Box>
                                <Box component={"form"} onSubmit={handleReSubmit}>
                                    <Box sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                    }}>
                                        <Button
                                            className='exitButton'
                                            variant={"outlined"}
                                            type={"submit"}
                                            sx={{mt: 3}}
                                            disabled={submitting}
                                        >
                                            Выслать письмо повторно
                                        </Button>
                                    </Box>
                                </Box>
                            </> }
                        </Paper>
                    </Box>
                </Container>
            </Box>
        </Box>
    );
}
