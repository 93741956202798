import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import {CustomProvider} from 'rsuite';
import ruRu from 'rsuite/locales/ru_RU';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<CustomProvider locale={ruRu}><App/></CustomProvider>);

