import {useState} from "react";
import {
    TextField,
    Box,
    Button,
    Dialog,
    DialogTitle,
    FormControl,
} from "@mui/material";
import {City} from "../../utils/network";

export default function CityEditComponent({cityType, open, onSave, onClose}) {
    const handleClose = (...ev) => {
        onClose(...ev);
    };

    const [errors, setErrors] = useState([]);

    const [cityTypeVar, setCityTypeVar] = useState(cityType);

    return (
        <Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth={false}>
            <DialogTitle>Редактирование показателей для города: {cityTypeVar.name}</DialogTitle>
            <Box sx={{p: 3, pt: 0, width: '100%', overflowX: 'hidden'}}>
                <FormControl fullWidth={true} sx={{marginBottom: '15px'}}>
                    <TextField
                        label="OTS"
                        value={cityTypeVar.ots}
                        variant="standard"
                        type="number"
                        sx={{step: "1", pattern: "\d*"}}
                        required
                        onChange={(ev) => {
                            setCityTypeVar((prev) => {
                                let newSate = {...prev,};
                                newSate['ots'] = ev.target.value;
                                return newSate;
                            })
                        }}
                        size={"small"}
                        error={!!errors.ots}
                        helperText={errors.ots || ''}
                    ></TextField>
                </FormControl>
                <FormControl fullWidth={true} sx={{marginBottom: '15px'}}>
                    <TextField
                        label="OTS для indoor"
                        value={cityTypeVar.ots_indoor}
                        variant="standard"
                        type="number"
                        sx={{step: "1", pattern: "\d*"}}
                        required
                        onChange={(ev) => {
                            setCityTypeVar((prev) => {
                                let newSate = {...prev,};
                                newSate['ots_indoor'] = ev.target.value;
                                return newSate;
                            })
                        }}
                        size={"small"}
                        error={!!errors.ots_indoor}
                        helperText={errors.ots_indoor || ''}
                    ></TextField>
                </FormControl>
                <FormControl fullWidth={true} sx={{marginBottom: '15px'}}>
                    <TextField
                        label="Частота"
                        value={cityTypeVar.frequency}
                        variant="standard"
                        type="number"
                        sx={{step: "1", pattern: "\d*"}}
                        required
                        onChange={(ev) => {
                            setCityTypeVar((prev) => {
                                let newSate = {...prev,};
                                newSate['frequency'] = ev.target.value;
                                return newSate;
                            })
                        }}
                        size={"small"}
                        error={!!errors.frequency}
                        helperText={errors.frequency || ''}
                    ></TextField>
                </FormControl>
                <FormControl fullWidth={true} sx={{marginBottom: '15px'}}>
                    <TextField
                        label="Частота использования (в процентах от срока жизни)"
                        value={cityTypeVar.usage_frequency}
                        variant="standard"
                        type="number"
                        sx={{step: "1", pattern: "\d*"}}
                        required
                        onChange={(ev) => {
                            setCityTypeVar((prev) => {
                                let newSate = {...prev,};
                                newSate['usage_frequency'] = ev.target.value;
                                return newSate;
                            })
                        }}
                        size={"small"}
                        error={!!errors.usage_frequency}
                        helperText={errors.usage_frequency || ''}
                    ></TextField>
                </FormControl>
                <Box sx={{mt: 3, display: 'flex', justifyContent: 'space-between', width: '100%'}}>
                    <Button
                        variant="contained"
                        className="calcButton"
                        onClick={(ev) => {
                            City.update(cityTypeVar).then((response) => {
                                if (response.errors) {
                                    setErrors(response.errors);
                                } else {
                                    onSave();
                                }
                            });

                            ev.stopPropagation();
                            ev.preventDefault();
                            return false;
                        }}
                        size="medium">
                        Сохранить
                    </Button>
                </Box>
            </Box>
        </Dialog>
    );
}
