import {Container, CssBaseline, Paper, Typography} from "@mui/material";
import {Box} from "@mui/material";
import React from "react";
import Toolbar from "@mui/material/Toolbar";
import LogoImg from "../../logo.png";
import {useNavigate} from "react-router-dom";
import {useEffect} from "react";

export default function PageNotFound() {

    const nav = useNavigate();

    useEffect(() => {
        nav('/');
    }, [])

    return (
        <Box>
            <CssBaseline/>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center'
                }}
                aria-label="mailbox folders"
            >
                <Toolbar/>
                <Container maxWidth="md">
                    <Box sx={{
                        marginTop: 3,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center'
                    }}>
                        <Paper
                            variant='elevation'
                            sx={{
                                minHeight: '30vh',
                                minWidth: '30vh',
                                borderRadius: '10px',
                                padding: '2em',
                                textAlign: 'center',
                            }}
                        >
                            <Box sx={{mb: 4}}>
                                <a href="/"><img src={LogoImg} alt="" className="logo"/></a>
                            </Box>

                            <Typography variant={"body2"}>Страница не найдена</Typography>

                        </Paper>
                    </Box>
                </Container>
            </Box>
        </Box>
    )
}