import {useEffect, useState} from 'react';
import {useSearchParams} from "react-router-dom";
import Box from "@mui/material/Box";
import TextField from '@mui/material/TextField';
import {Calculator, Statistics} from "../../utils/network";
import Select from 'react-select';
import DateRangePicker from "rsuite/DateRangePicker";
import {useTheme} from '@mui/material/styles';

export default function ({onFilterChange}) {
    const [query, setQuery] = useSearchParams();
    const theme = useTheme();

    let preselectedMerchIDs = [];
    if (query.get('merch')) {
        preselectedMerchIDs = query.get('merch').split('_');
    }

    let preselectedCityTypesIDs = [];
    if (query.get('city')) {
        preselectedCityTypesIDs = query.get('city').split('_');
    }

    let preselectedPeriod = null;
    let preselectedPeriodStart = parseInt(query.get('period.start') + '', 10);
    let preselectedPeriodEnd = parseInt(query.get('period.end') + '', 10);
    if (preselectedPeriodStart && preselectedPeriodEnd) {
        preselectedPeriod = [new Date(preselectedPeriodStart), new Date(preselectedPeriodEnd)];
    }

    const [preselectedValuesInstalled, setPreselectedValuesInstalled] = useState(false);
    const [merchOptions, setMerchOptions] = useState([]);
    const [cityTypesOptions, setCityTypesOptions] = useState([]);
    const [merch, setMerch] = useState([]);
    const [cityTypes, setCityTypes] = useState([]);

    const [period, setPeriod] = useState(preselectedPeriod);

    const [search, setSearch] = useState(query.get('search') ?? '');
    const [countMin, setCountMin] = useState(query.get('count.min') ?? '');
    const [countMax, setCountMax] = useState(query.get('count.max') ?? '');
    const [priceMin, setPriceMin] = useState(query.get('price.min') ?? '');
    const [priceMax, setPriceMax] = useState(query.get('price.max') ?? '');

    const updateQuery = () => {
        let selectedMerch = merch.map((item) => item.value);
        let selectedCityTypes = cityTypes.map((item) => item.value);

        let params = {};

        if (selectedMerch.length > 0) params['merch'] = selectedMerch.join('_');
        if (selectedCityTypes.length > 0) params['city'] = selectedCityTypes.join('_');

        if (period && period[0]) {
            period[0].setSeconds(0, 0);
            params['period.start'] = period[0].getTime().toString();
        }
        if (period && period[1]) {
            period[1].setSeconds(0, 0);
            params['period.end'] = period[1].getTime().toString();
        }

        if (search) {
            params['search'] = search;
        }

        if (priceMin) {
            params['price.min'] = priceMin;
        }

        if (priceMax) {
            params['price.max'] = priceMax;
        }

        if (countMin) {
            params['count.min'] = countMin;
        }

        if (countMax) {
            params['count.max'] = countMax;
        }

        setQuery(params);
    };

    useEffect(() => {
        if (merchOptions.length && cityTypesOptions.length) {
            setMerch(preselectedMerchIDs.map((preselectedMerchID) => {
                return merchOptions.find((option) => option.value == preselectedMerchID);
            }))

            setCityTypes(preselectedCityTypesIDs.map((preselectedCityTypeID) => {
                return cityTypesOptions.find((option) => option.value == preselectedCityTypeID);
            }))

            setPreselectedValuesInstalled(true);
        }
    }, [merchOptions, cityTypesOptions]);

    useEffect(() => {
        if (preselectedValuesInstalled) {
            updateQuery();
        }
    }, [period, search, merch, cityTypes, countMin, countMax, priceMin, priceMax]);

    useEffect(() => {
        let filter = {};

        if (merch.length > 0) filter.merch = merch;
        if (cityTypes.length > 0) filter.city = cityTypes;

        if (period && period[0]) {
            filter['period.start'] = period[0].getTime();
        }

        if (period && period[1]) {
            filter['period.end'] = period[1].getTime();
        }

        if (search) {
            filter.search = search;
        }

        if (priceMin) {
            filter['price.min'] = priceMin;
        }

        if (priceMax) {
            filter['price.max'] = priceMax;
        }

        if (countMin) {
            filter['count.min'] = countMin;
        }

        if (countMax) {
            filter['count.max'] = countMax;
        }

        onFilterChange(filter);
    }, [query]);

    useEffect(() => {
        Calculator.stuff().then((result) => {
            setMerchOptions(result.merch.map((value) => ({label: value.name, value: value.id})));
            setCityTypesOptions(result.cityTypes.map((value) => ({label: value.name, value: value.id})));
        });

        Statistics.totals().then((totals) => {
            console.log(totals);
        });
    }, [])

    return (
        <>
            <Box sx={{
                display: 'block',
                gap: '10px',
                width: '100%',
                mb: 2
            }}>
                <DateRangePicker
                    block
                    appearance={'default'}
                    size={'md'}
                    format="yyyy-MM-dd HH:mm"
                    label="Период"
                    placeholder="без ограничений"
                    onChange={setPeriod}
                    value={period}
                    disabled={!preselectedValuesInstalled}
                />
            </Box>
            <Box sx={{
                display: 'block',
                gap: '10px',
                width: '100%',
                mb: 2,
            }}>
                <TextField
                    label="ФИО / Организация / Должность"
                    sx={{width: '100%'}}
                    size="small"
                    defaultValue={search}
                    onKeyDown={(ev) => {
                        if (ev.key === "Enter") {
                            setSearch(ev.target.value);
                        }
                    }}
                    onBlur={(ev) => {
                        setSearch(ev.target.value);
                    }}
                />
            </Box>
            <Box sx={{
                display: 'flex',
                gap: '10px',
                mb: 2
            }}>
                <TextField
                    label="Количество, от"
                    defaultValue={countMin}
                    type="number"
                    sx={{step: "1", pattern: "\d*", min: "0"}}
                    required
                    onChange={(ev) => {
                        if (ev.target.value > 1000000) {
                            ev.target.value = 1000000;
                        }
                        if (ev.target.value < 0) {
                            ev.target.value = 0;
                        }
                    }}
                    onKeyDown={(ev) => {
                        if (ev.key === "Enter") {
                            setCountMin(ev.target.value);
                        }
                    }}
                    onBlur={(ev) => {
                        setCountMin(ev.target.value);
                    }}
                    size={"small"}
                ></TextField>
                <TextField
                    label="Количество, до"
                    defaultValue={countMax}
                    type="number"
                    sx={{step: "1", pattern: "\d*", min: "0"}}
                    required
                    onChange={(ev) => {
                        if (ev.target.value > 1000000) {
                            ev.target.value = 1000000;
                        }
                        if (ev.target.value < 0) {
                            ev.target.value = 0;
                        }
                    }}
                    onKeyDown={(ev) => {
                        if (ev.key === "Enter") {
                            setCountMax(ev.target.value);
                        }
                    }}
                    onBlur={(ev) => {
                        setCountMax(ev.target.value);
                    }}
                    size={"small"}
                ></TextField>
            </Box>
            <Box sx={{
                display: 'flex',
                gap: '10px',
                mb: 2
            }}>
                <TextField
                    label="Цена, от"
                    defaultValue={priceMin}
                    type="number"
                    sx={{step: "1", pattern: "\d*", min: "0"}}
                    required
                    onChange={(ev) => {
                        if (ev.target.value > 1000000) {
                            ev.target.value = 1000000;
                        }
                        if (ev.target.value < 0) {
                            ev.target.value = 0;
                        }
                    }}
                    onKeyDown={(ev) => {
                        if (ev.key === "Enter") {
                            setPriceMin(ev.target.value);
                        }
                    }}
                    onBlur={(ev) => {
                        setPriceMin(ev.target.value);
                    }}
                    size={"small"}
                ></TextField>
                <TextField
                    label="Цена, до"
                    defaultValue={priceMax}
                    type="number"
                    sx={{step: "1", pattern: "\d*", min: "0"}}
                    required
                    onChange={(ev) => {
                        if (ev.target.value > 1000000) {
                            ev.target.value = 1000000;
                        }
                        if (ev.target.value < 0) {
                            ev.target.value = 0;
                        }
                    }}
                    onKeyDown={(ev) => {
                        if (ev.key === "Enter") {
                            setPriceMax(ev.target.value);
                        }
                    }}
                    onBlur={(ev) => {
                        setPriceMax(ev.target.value);
                    }}
                    size={"small"}
                ></TextField>
            </Box>
            <Box sx={{
                display: 'flex',
                gap: '10px',
                mb: 2
            }}>
                <Select
                    id="merch-select"
                    value={merch}
                    options={merchOptions}
                    isMulti
                    placeholder="Мерч"
                    onChange={(merch) => {
                        setMerch(merch);
                    }}
                    styles={{
                        container: (base) => ({
                            ...base,
                            width: '100%',
                            display: 'inline-block'
                        })
                    }}
                    isDisabled={!preselectedValuesInstalled}
                />
            </Box>
            <Box sx={{
                display: 'flex',
                gap: '10px',
                mb: 2
            }}>
                <Select
                    id="city-types-select"
                    value={cityTypes}
                    options={cityTypesOptions}
                    isMulti
                    placeholder="Тип города"
                    onChange={(cityTypes) => {
                        setCityTypes(cityTypes);
                    }}
                    styles={{
                        container: (base) => ({
                            ...base,
                            width: '100%',
                            display: 'inline-block'
                        })
                    }}
                    isDisabled={!preselectedValuesInstalled}
                    SelectProps={{MenuProps: {MenuListProps: {background: theme.palette.background}}}}
                />
            </Box>
        </>
    );
}
