import Toolbar from "@mui/material/Toolbar";
import React, {useState} from "react";
import {useNavigate} from "react-router-dom";
import {
    Box,
    Button,
    Container,
    TextField,
    CssBaseline,
    Paper
} from '@mui/material'
import Link from '@mui/material/Link';

import {BASE_URL, Requests} from '../../utils/network';
import LogoImg from "../../logo.png";

export function LoginPage() {
    const nav = useNavigate();
    const [errors, setErrors] = useState([]);

    const handleSubmit = (ev) => {
        ev.preventDefault();
        const formData = new FormData(ev.currentTarget);

        const loginCredentials = {
            email: formData.get('email'),
            password: formData.get('password')
        };

        Requests.get(BASE_URL + '/sanctum/csrf-cookie')
            .then(() => {
                Requests.post(BASE_URL + '/api/login', null, loginCredentials)
                    .then(async (response) => {
                        if (response.ok) {
                            Requests.user = await response.json();
                            nav('/');
                            window.location.reload();
                        } else {
                            return response.json();
                        }
                    })
                    .then((responseJson) => {
                        if (responseJson && responseJson.errors) {
                            setErrors(responseJson.errors);
                        }
                    });
            });
    };

    return (
        <Box>
            <CssBaseline/>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center'
                }}
                aria-label="mailbox folders"
            >
                <Toolbar/>
                <Container maxWidth="md">
                    <Box sx={{
                        marginTop: 3,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center'
                    }}>
                        <Box sx={{mb: 4}}>
                            <a href="/"><img src={LogoImg} alt="" className="logo"/></a>
                        </Box>
                        <Paper
                            variant='outlined'

                            sx={{
                                minHeight: '30vh',
                                borderRadius: '10px',
                                border: 'solid #000000 1px',
                                background: '#ECF0F4',
                                padding: '2em',
                            }}
                        >
                            <Box component={"form"} onSubmit={handleSubmit}>
                                <TextField
                                    margin={"normal"}
                                    required
                                    fullWidth
                                    id="email"
                                    name="email"
                                    label="Логин (email)"
                                    autoComplete="email"
                                    autoFocus
                                    error={!!errors.email}
                                    helperText={errors.email || ''}
                                />
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    name="password"
                                    label="Пароль"
                                    type="password"
                                    id="password"
                                    autoComplete="current-password"
                                    error={!!errors.password}
                                    helperText={errors.password || ''}
                                />
                                <Box sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                }}>
                                    <Button
                                        className='exitButton'
                                        variant={"outlined"}
                                        type={"submit"}
                                        sx={{mt: 3}}
                                    >
                                        Вход
                                    </Button>
                                    <Link
                                        sx={{mt: 3}}
                                        variant={"body1"}
                                        href={"/reg"}
                                    >
                                        РЕГИСТРАЦИЯ
                                    </Link>
                                </Box>
                            </Box>
                        </Paper>
                    </Box>
                </Container>
            </Box>
        </Box>
    );
}
