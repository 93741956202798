import './App.css'
import 'rsuite/dist/rsuite.min.css'

import React from 'react'
import {
    Route,
    Outlet,
    RouterProvider,
    createBrowserRouter,
    createRoutesFromElements,
} from 'react-router-dom'

import {LoginPage} from "./components/LoginPage/LoginPage";
import {RegistrationPage} from "./components/RegistrationPage/RegistrationPage";
import {CheckEmail} from "./components/CheckEmail/CheckEmail";
import {SetPassword} from "./components/SetPassword/SetPassword";
import AppBar from './components/AppBar/AppBar';
import Calculator from './components/Calculator/Calculator';
import Editor from './components/Editor/Editor';
import Stats from './components/Stats/Stats';
import Footer from './components/Footer/Footer';
import TermsOfUse from './components/TermsOfUse/TermsOfUse';
import PageNotFound from './components/PageNotFound/PageNotFound';
import PublicationConditions from './components/PublicationConditions/PublicationConditions';
import ThemeProvider from '@mui/material/styles/ThemeProvider';
import createTheme from '@mui/material/styles/createTheme';
import GlobalStyles from '@mui/material/GlobalStyles';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';

import './App.css';

import {Requests} from './utils/network'

import {
    useEffect
} from 'react'

const theme = createTheme({
    typography: {
        button: {
            textTransform: 'none'
        }
    }
});

export let isAuthenticated = !!Requests.user;

export let isAdmin = isAuthenticated && (Requests.user.role === 4);
export let isManager = isAuthenticated && (Requests.user.role === 2);

function checkForAuthentication() {
    Requests.requestAuthenticated().then(async (resp) => {
        if (resp && resp.status === 401) {
            Requests.clearData();
            isAuthenticated = false;
            console.log("checkForAuthentication drop data");
        }

        console.log("requestAuthenticated", resp);
    });
}

function App() {
    console.log("isAuthenticated", isAuthenticated);
    const router = createBrowserRouter(
        createRoutesFromElements(
            isAuthenticated && isAdmin ?
                <Route path="/" element={<AppInner/>}>
                    <Route path="/" element={<Calculator/>}/>
                    <Route path="/stats" element={<Stats/>}/>
                    <Route path="/editor" element={<Editor/>}/>
                    <Route path="/terms-of-use" element={<TermsOfUse/>}/>
                    <Route path="/publication" element={<PublicationConditions/>}/>
                    <Route path="*" element={<PageNotFound/>}/>
                </Route> :
                (isAuthenticated && isManager ?
                        <Route path="/" element={<AppInner/>}>
                            <Route path="/" element={<Calculator/>}/>
                            <Route path="/stats" element={<Stats/>}/>
                            <Route path="/terms-of-use" element={<TermsOfUse/>}/>
                            <Route path="/publication" element={<PublicationConditions/>}/>
                            <Route path="*" element={<PageNotFound/>}/>
                        </Route> :
                    (
                        isAuthenticated ?
                            <Route path="/" element={<AppInner/>}>
                                <Route path="/" element={<Calculator/>}/>
                                <Route path="/terms-of-use" element={<TermsOfUse/>}/>
                                <Route path="/publication" element={<PublicationConditions/>}/>
                                <Route path="*" element={<PageNotFound/>}/>
                            </Route> :
                            <Route path="/" element={<AppOuter/>}>
                                <Route path="/" index element={<LoginPage/>}/>
                                <Route path="/reg" index element={<RegistrationPage/>}/>
                                <Route path="/check-email" index element={<CheckEmail/>}/>
                                <Route path="/set-password" index element={<SetPassword/>}/>
                                <Route path="/terms-of-use" element={<TermsOfUse/>}/>
                                <Route path="/publication" element={<PublicationConditions/>}/>
                                <Route path="*" element={<PageNotFound/>}/>
                            </Route>
                    )
                )
        )
    );

    return (
        <ThemeProvider theme={theme}>
            <GlobalStyles styles={{
                ".App": {backgroundColor: '#FFFFFF', height: "100%"},
                "html, body": {width: '100%', height: '100%'},
                "#root": {height: "100%"},
                ".logo": {height: "4vh"}
            }}/>
            <RouterProvider router={router}/>
        </ThemeProvider>
    );
}

function AppInner() {
    useEffect(() => {
        checkForAuthentication();
    }, []);

    let user = Requests.user;
    console.log("App user", user);

    return (
        <>
            <Container maxWidth="lg" component="main" sx={{paddingBottom: "8rem"}}>
                <CssBaseline/>
                <AppBar/>
                <Outlet/>
            </Container>
            <Footer/>
        </>
    );
}

function AppOuter() {
    useEffect(() => {
        checkForAuthentication();
    }, []);

    return (
        <>
            <Container maxWidth="lg" component="main" sx={{paddingBottom: "8rem"}}>
                <CssBaseline/>
                <Outlet/>
            </Container>
            <Footer/>
        </>
    );
}

export default App;
