import {useState} from "react";
import {
    TextField,
    Grid,
    Box,
    Button,
    Dialog,
    DialogTitle,
    FormControl,
    Select,
    MenuItem,
    InputLabel
} from "@mui/material";
import {Merch} from "../../utils/network";

export default function MerchEditComponent({merch, open, onSave, onClose, merchGroups}) {
    const handleClose = (...ev) => {
        onClose(...ev);
    };

    const [errors, setErrors] = useState([]);

    const [merchVar, setMerchVar] = useState(merch || {
        id: null,
        title: '',
        merch_group_id: null,
        coefficient: null,
        years: null,
        indoor: null,
        experiment_coefficient: null,
        polling_coefficient: null,
        season_coefficient_spring: null,
        season_coefficient_summer: null,
        season_coefficient_autumn: null,
        season_coefficient_winter: null,
        location_coefficient_street: null,
        location_coefficient_public: null,
        location_coefficient_transport: null,
    });

    return (
        <Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth={false}>
            <DialogTitle>{merch && merch.id ? 'Редактирование мерча' : 'Добавление мерча'}</DialogTitle>
            <Box sx={{p: 3, pt: 0, width: '100%', overflowX: 'hidden'}}>
                <FormControl fullWidth={true} sx={{marginBottom: '15px'}}>
                    <TextField
                        value={merchVar.name}
                        variant="standard"
                        type="text"
                        required
                        onChange={(ev) => {
                            setMerchVar((prev) => {
                                let newSate = {...prev,};
                                newSate['name'] = ev.target.value;
                                return newSate;
                            })
                        }}
                        size={"small"}
                        placeholder="Название"
                        error={!!errors.name}
                        helperText={errors.name || ''}
                    ></TextField>
                </FormControl>
                <FormControl fullWidth={true} sx={{marginBottom: '15px'}}>
                    <InputLabel sx={{ml: '-14px'}}>Тип</InputLabel>
                    <Select
                        value={merchVar.indoor}
                        variant="standard"
                        type="text"
                        required
                        onChange={(ev) => {
                            setMerchVar((prev) => {
                                let newSate = {...prev,};
                                newSate['indoor'] = ev.target.value;
                                return newSate;
                            })
                        }}
                        size={"small"}
                        placeholder="Тип"
                        error={!!errors.indoor}
                    >
                        <MenuItem value={false} key={0}>Outdoor</MenuItem>
                        <MenuItem value={true} key={1}>Indoor</MenuItem>
                    </Select>
                </FormControl>
                <FormControl fullWidth={true} sx={{marginBottom: '15px'}}>
                    <InputLabel sx={{ml: '-14px'}}>Категория</InputLabel>
                    <Select
                        value={merchVar.merch_group_id}
                        variant="standard"
                        type="text"
                        required
                        onChange={(ev) => {
                            setMerchVar((prev) => {
                                let newSate = {...prev,};
                                newSate['merch_group_id'] = ev.target.value;
                                return newSate;
                            })
                        }}
                        size={"small"}
                        placeholder="Категория"
                        error={!!errors.merch_group_id}
                    >
                        {merchGroups.map((merchGroup, key) => (
                            <MenuItem value={merchGroup.id} key={key}>{merchGroup.name}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <Grid container spacing={2}>
                    <Grid item xs={4}>
                        <FormControl fullWidth={true} sx={{marginBottom: '15px'}}>
                            <TextField
                                label="Время жизни (годы)"
                                value={merchVar.years}
                                variant="standard"
                                type="number"
                                sx={{step: "1", pattern: "\d*"}}
                                required
                                onChange={(ev) => {
                                    setMerchVar((prev) => {
                                        let newSate = {...prev,};
                                        newSate['years'] = ev.target.value;
                                        return newSate;
                                    })
                                }}
                                size={"small"}
                                error={!!errors.years}
                                helperText={errors.years || ''}
                            ></TextField>
                        </FormControl>
                        <FormControl fullWidth={true} sx={{marginBottom: '15px'}}>
                            <TextField
                                label="Корректирующий коэффициент (время жизни)"
                                value={merchVar.coefficient}
                                variant="standard"
                                type="number"
                                sx={{step: "1", pattern: "\d*"}}
                                required
                                onChange={(ev) => {
                                    setMerchVar((prev) => {
                                        let newSate = {...prev,};
                                        newSate['coefficient'] = ev.target.value;
                                        return newSate;
                                    })
                                }}
                                size={"small"}
                                error={!!errors.coefficient}
                                helperText={errors.coefficient || ''}
                            ></TextField>
                        </FormControl>
                        <FormControl fullWidth={true} sx={{marginBottom: '15px'}}>
                            <TextField
                                label="Коэффициент эксперимента"
                                value={merchVar.experiment_coefficient}
                                variant="standard"
                                type="number"
                                sx={{step: "1", pattern: "\d*"}}
                                required
                                onChange={(ev) => {
                                    setMerchVar((prev) => {
                                        let newSate = {...prev,};
                                        newSate['experiment_coefficient'] = ev.target.value;
                                        return newSate;
                                    })
                                }}
                                size={"small"}
                                error={!!errors.experiment_coefficient}
                                helperText={errors.experiment_coefficient || ''}
                            ></TextField>
                        </FormControl>
                        <FormControl fullWidth={true} sx={{marginBottom: '15px'}}>
                            <TextField
                                label="Коэффициент опроса"
                                value={merchVar.polling_coefficient}
                                variant="standard"
                                type="number"
                                sx={{step: "1", pattern: "\d*"}}
                                required
                                onChange={(ev) => {
                                    setMerchVar((prev) => {
                                        let newSate = {...prev,};
                                        newSate['polling_coefficient'] = ev.target.value;
                                        return newSate;
                                    })
                                }}
                                size={"small"}
                                error={!!errors.polling_coefficient}
                                helperText={errors.polling_coefficient || ''}
                            ></TextField>
                        </FormControl>
                    </Grid>
                    <Grid item xs={4}>
                        <FormControl fullWidth={true} sx={{marginBottom: '15px'}}>
                            <TextField
                                label="Коэффициент влияния сезона на видимость (весна)"
                                value={merchVar.season_coefficient_spring}
                                variant="standard"
                                type="number"
                                sx={{step: "1", pattern: "\d*"}}
                                required
                                onChange={(ev) => {
                                    setMerchVar((prev) => {
                                        let newSate = {...prev,};
                                        newSate['season_coefficient_spring'] = ev.target.value;
                                        return newSate;
                                    })
                                }}
                                size={"small"}
                                error={!!errors.season_coefficient_spring}
                                helperText={errors.season_coefficient_spring || ''}
                            ></TextField>
                        </FormControl>
                        <FormControl fullWidth={true} sx={{marginBottom: '15px'}}>
                            <TextField
                                label="Коэффициент влияния сезона на видимость (лето)"
                                value={merchVar.season_coefficient_summer}
                                variant="standard"
                                type="number"
                                sx={{step: "1", pattern: "\d*"}}
                                required
                                onChange={(ev) => {
                                    setMerchVar((prev) => {
                                        let newSate = {...prev,};
                                        newSate['season_coefficient_summer'] = ev.target.value;
                                        return newSate;
                                    })
                                }}
                                size={"small"}
                                error={!!errors.season_coefficient_summer}
                                helperText={errors.season_coefficient_summer || ''}
                            ></TextField>
                        </FormControl>
                        <FormControl fullWidth={true} sx={{marginBottom: '15px'}}>
                            <TextField
                                label="Коэффициент влияния сезона на видимость (осень)"
                                value={merchVar.season_coefficient_autumn}
                                variant="standard"
                                type="number"
                                sx={{step: "1", pattern: "\d*"}}
                                required
                                onChange={(ev) => {
                                    setMerchVar((prev) => {
                                        let newSate = {...prev,};
                                        newSate['season_coefficient_autumn'] = ev.target.value;
                                        return newSate;
                                    })
                                }}
                                size={"small"}
                                error={!!errors.season_coefficient_autumn}
                                helperText={errors.season_coefficient_autumn || ''}
                            ></TextField>
                        </FormControl>
                        <FormControl fullWidth={true} sx={{marginBottom: '15px'}}>
                            <TextField
                                label="Коэффициент влияния сезона на видимость (зима)"
                                value={merchVar.season_coefficient_winter}
                                variant="standard"
                                type="number"
                                sx={{step: "1", pattern: "\d*"}}
                                required
                                onChange={(ev) => {
                                    setMerchVar((prev) => {
                                        let newSate = {...prev,};
                                        newSate['season_coefficient_winter'] = ev.target.value;
                                        return newSate;
                                    })
                                }}
                                size={"small"}
                                error={!!errors.season_coefficient_winter}
                                helperText={errors.season_coefficient_winter || ''}
                            ></TextField>
                        </FormControl>
                    </Grid>
                    <Grid item xs={4}>
                        <FormControl fullWidth={true} sx={{marginBottom: '15px'}}>
                            <TextField
                                label="Коэффициент влияния локации (улица)"
                                value={merchVar.location_coefficient_street}
                                variant="standard"
                                type="number"
                                sx={{step: "1", pattern: "\d*"}}
                                required
                                onChange={(ev) => {
                                    setMerchVar((prev) => {
                                        let newSate = {...prev,};
                                        newSate['location_coefficient_street'] = ev.target.value;
                                        return newSate;
                                    })
                                }}
                                size={"small"}
                                error={!!errors.location_coefficient_street}
                                helperText={errors.location_coefficient_street || ''}
                            ></TextField>
                        </FormControl>
                        <FormControl fullWidth={true} sx={{marginBottom: '15px'}}>
                            <TextField
                                label="Коэффициент влияния локации (общественное место)"
                                value={merchVar.location_coefficient_public}
                                variant="standard"
                                type="number"
                                sx={{step: "1", pattern: "\d*"}}
                                required
                                onChange={(ev) => {
                                    setMerchVar((prev) => {
                                        let newSate = {...prev,};
                                        newSate['location_coefficient_public'] = ev.target.value;
                                        return newSate;
                                    })
                                }}
                                size={"small"}
                                error={!!errors.location_coefficient_public}
                                helperText={errors.location_coefficient_public || ''}
                            ></TextField>
                        </FormControl>
                        <FormControl fullWidth={true} sx={{marginBottom: '15px'}}>
                            <TextField
                                label="Коэффициент влияния локации (транспорт)"
                                value={merchVar.location_coefficient_transport}
                                variant="standard"
                                type="number"
                                sx={{step: "1", pattern: "\d*"}}
                                required
                                onChange={(ev) => {
                                    setMerchVar((prev) => {
                                        let newSate = {...prev,};
                                        newSate['location_coefficient_transport'] = ev.target.value;
                                        return newSate;
                                    })
                                }}
                                size={"small"}
                                error={!!errors.location_coefficient_transport}
                                helperText={errors.location_coefficient_transport || ''}
                            ></TextField>
                        </FormControl>
                    </Grid>
                </Grid>

                <Box sx={{mt: 3, display: 'flex', justifyContent: 'space-between', width: '100%'}}>
                    <Button
                        variant="contained"
                        className="calcButton"
                        onClick={(ev) => {
                            if (merchVar.id) {
                                Merch.update(merchVar).then((response) => {
                                    if (response.errors) {
                                        setErrors(response.errors);
                                    } else {
                                        onSave();
                                    }
                                });
                            } else {
                                Merch.create(merchVar).then((response) => {
                                    if (response.errors) {
                                        setErrors(response.errors);
                                    } else {
                                        onSave();
                                    }
                                });
                            }

                            ev.stopPropagation();
                            ev.preventDefault();
                            return false;
                        }}
                        size="medium">
                        Сохранить
                    </Button>
                    {merch && merch.id ? <Button
                        variant="outlined"
                        className="secondaryButton"
                        onClick={(ev) => {
                            if (window.confirm('Удалить мерч ' + merchVar['name'] + '?')) {
                                Merch.delete(merchVar).then((response) => {
                                    if (response.errors) {
                                        setErrors(response.errors);
                                    } else {
                                        onSave();
                                    }
                                });
                            }

                            ev.stopPropagation();
                            ev.preventDefault();
                            return false;
                        }}
                    >Удалить</Button> : ''}
                </Box>
            </Box>
        </Dialog>
    );
}
